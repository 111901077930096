import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import { Hero } from '../components/hero'
import { Footer } from '../components/footer'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'
import styled from 'styled-components'
import Svg from './svg/pamatniki.svg'
import Svg2 from './svg/CatalogLogo.svg'

import bg from '../components/img/index.jpg'

const About = styled.section`
  img {
    float: right;
    width: 52%;
  }
  h2 {
    font-family: 'GT Walsheim Pro Bold';
  }
`
const Bg = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  background: url(${bg}) center center / cover;
  z-index: -1;
`

const Card = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 100%;
  border-radius: 8px;
  min-height: 200px;
  margin: 30px 0;

  img {
    width: 60%;
  }

  &:hover {
    background-color: #fafafa;
  }

  .first {
    display: flex;
    justify-content: center;
  }
  .second {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    color: #000;
    margin-right: 15px;
  }
  .second-head {
    font-family: 'GT Walsheim Pro Bold';
    padding-bottom: 15px;
  }
`

const RootIndex = (props) => {
  const posts = props.data.allContentfulBlogPost.edges
  const [author] = props.data.allContentfulPerson.edges
  return (
    <>
      <Bg></Bg>
      <Layout location={props.location}>
        <div
          style={{
            // background: '#f2f1e2',
            borderRadius: 5,
            // boxShadow: '15px 10px 40px -2px rgba(0,0,0,.8)',
          }}
        >
          <Helmet title="Крымское отделение ВООПИиК" />
          <Hero data={author.node} />
          <About className="wrapper">
            <h2 className="section-headline">О нас</h2>
            <div>
              <p>
                В обычной жизни города мы редко замечаем памятники на своем
                пути. Иногда кажется, что они должны быть огромными маяками, о
                которые надо специально споткнуться, чтобы вспомнить, или
                огромной памятной стеной, в которую мы должны упереться, чтобы
                вытащить наушники и, подняв глаза, прочесть, что же произошло на
                этом месте в далекие времена. Настоящие памятники не всегда
                такие. Они могут быть маленькой табличкой на полу со скромной
                надписью: «Здесь лежит Суворов» или памятным знаком на стене,
                повествующим о появлении первой пожарной службы в городе, что
                изменило жизнь тысяч людей. И более того – даже то, что мы
                памятником считать не привыкли, может им являться. Дверь работы
                мастера XIX века, изящный чугунный люк с гравировкой, мозаики,
                по которым наш привычный взгляд безразлично скользит, когда мы
                проезжаем по дороге Симферополь-Евпатория…
              </p>
              <p>
                И все это, безусловно, надо защищать, чтобы в будущем смогли
                увидеть и задуматься люди над причиной появления памятника,
                сохранив таким образом наш культурный код. А что бы защищать –
                надо знать, что они такое. И именно для того чтобы показать вам,
                где могут скрываться или, наоборот, гордо расположились
                памятники, и создан наш сайт.
              </p>
            </div>
          </About>
          <About className="wrapper">
            <h2 className="section-headline">Проекты</h2>
            <Link to="/memory">
              <Card>
                <div className="first">
                  <img src={Svg} alt="" />
                </div>
                <div className="second">
                  <span className="second-head">
                    От памятника к памяти: Мемориализация культурного наследия
                    Старорусского кладбища
                  </span>
                  <span>
                    Составление историко-культурного опорного плана местности,
                    формирование каталога исторических справок и создание
                    интернет-ресурса о памятниках этого места.
                  </span>
                </div>
              </Card>
            </Link>
          </About>
          <About className="wrapper">
            <h2 className="section-headline">Каталоги</h2>
            <Link to="/simf">
              <Card>
                <div className="first">
                  <img src={Svg2} alt="" />
                </div>
                <div className="second">
                  <span className="second-head">
                    Объекты Воинского кладбища Симферополя
                  </span>
                </div>
              </Card>
            </Link>
            <Link to="/catalog">
              <Card>
                <div className="first">
                  <img src={Svg2} alt="" />
                </div>
                <div className="second">
                  <span className="second-head">
                    Объекты культурного наследия Старорусского кладбища
                  </span>
                </div>
              </Card>
            </Link>
            <Link to="/war">
              <Card>
                <div className="first">
                  <img src={Svg2} alt="" />
                </div>
                <div className="second">
                  <span className="second-head">
                    Объекты военно-мемориального наследия Республики Крым
                  </span>
                </div>
              </Card>
            </Link>
          </About>
          {Form()}
        </div>
        <Footer
          text="При финансовой поддержке Фонда президентских грантов, проект №
          20-1-032292"
        />
      </Layout>
    </>
  )
}

function srcToFile(src, fileName, mimeType){
  return (fetch(src)
      .then(function(res){return res.arrayBuffer();})
      .then(function(buf){return new File([buf], fileName, {type:mimeType});})
  );
}

const Form = (props) => {
  const [files, setFiles] = React.useState([])
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'application/pdf': [],
    },
    // onDrop: (acceptedFiles) => {
    //   setFiles(
    //     acceptedFiles.map((file) =>
    //       Object.assign(file, {
    //         preview: URL.createObjectURL(file),
    //         image: new Image(file)
    //       })
    //     )
    //   )
    // },
  })

  const onFormSubmit = (event) => {
    event.preventDefault()
    let allFormData = {}

    const formData = new FormData(event.target)
    for (let pair of formData.entries()) {
      allFormData = {
        ...allFormData,
        [pair[0]]: pair[1],
      }
    }
    
    // allFormData = {
    //   ...allFormData,
    //   photos: acceptedFiles,
    // }

    fetch('.netlify/functions/send-contact-email', {
      method: 'POST',
      // headers: {
      //   // 'Content-Type': 'multipart/form-data;application/json;charset=utf-8',
      //   'content-type': 'multipart/form-data;boundary=----WebKitFormBoundary9dY61YWuNPKdlGOE'
      // },
      body: JSON.stringify(allFormData),
      // body: formData
    })
  }

  // post('/.netlify/functions/send-contact-email')
  return (
    <section className="wrapper">
      <h2 className="section-headline">Подайте заявку</h2>
      <div>
        <span className="second-head">
          Если вам известны объекты, которые, на ваш взгляд, обладают признаками
          культурного наследия, то не оставайтесь равнодушными и заполните
          заявку на нашем сайте.
        </span>
        <FormContainer onSubmit={onFormSubmit}>
          <FormGroup>
            <label>Наименование объекта</label>
            <Input name="name" type="text" placeholder="Наименование объекта" />
          </FormGroup>
          <FormGroup>
            <label>Местонахождение объекта</label>
            <Input
              name="geo"
              type="text"
              placeholder="Крым, г.Симферополь, ул. Старозенитная 1"
            />
          </FormGroup>
          <FormGroup>
            <label>ФИО</label>
            <Input
              name="initials"
              type="text"
              placeholder="Иванов Иван Иванонич"
            />
          </FormGroup>
          <FormGroup>
            <label>Номер контактного телефона</label>
            <Input
              name="phone"
              type="tel"
              placeholder="Номер контактного телефона"
            />
          </FormGroup>
          <FormGroup>
            <label>Адрес электронной почты</label>
            <Input name="email" type="email" placeholder="mymail@yandex.ru" />
          </FormGroup>
          <FormGroup>
            <label>Ссылки на социальные сети</label>
            <Input
              name="socials"
              type="text"
              placeholder="https://t.me/example"
            />
          </FormGroup>
          {/* <FormGroup>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input name="photos" {...getInputProps()} />
              <Span>Нажмите для загрузки фотографий объекта</Span>
              <em>в формате .jpg, .png, .pdf</em>
            </div>
            <aside>
              <H4>Успешно загружено:</H4>
              <Span>{acceptedFiles.length}</Span>
            </aside>
          </FormGroup> */}
          <FormGroup>
            <label>Опишите ценность объекта</label>
            <Textarea name="value" maxLength={1000} res></Textarea>
          </FormGroup>
          <div>
            <input type="checkbox" />
            <label>
              Согласен с{' '}
              <Link
                to="/privacy-policy"
                style={{
                  textDecoration: 'underline',
                }}
              >
                обработкой данных
              </Link>
            </label>
          </div>
          <Button type="submit">Отправить</Button>
        </FormContainer>
      </div>
    </section>
  )
}

const Button = styled.button`
  color: white;
  height: 44px;
  background-color: #8247e5;
  border-radius: 4px;
  border: 1px solid #ddd;
`

const H4 = styled.h4`
  margin: 0;
  padding-top: 8px;
  padding-bottom: 4px;
`

const Span = styled.span`
  display: block;
`

const Textarea = styled.textarea`
  outline: 0;
  padding: 12px;
  height: 130px;
  border-radius: 4px;
  border: 1px solid #ddd;
  resize: none;
`

const Input = styled.input`
  outline: 0;
  padding: 12px;
  min-width: 240px;
  border-radius: 4px;
  border: 1px solid #ddd;
`

const FormContainer = styled.form`
  /* display: flex;
  flex-direction: column; */
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr auto auto;
  margin-top: 20px;
`

const FormGroup = styled.div`
  display: grid;
  grid-gap: 4px;

  .dropzone {
    outline: 0;
    padding: 12px;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
`

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      limit: 6
    ) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
          heroImage: image {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`
