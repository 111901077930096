import React from 'react'
import Img from 'gatsby-image'

import styles from './hero.module.css'

export const Hero = ({ data }) => (
  <div className={styles.hero}>
    <div className={styles.heroDetails}>
      {/* <h3 className={styles.heroHeadline}>{data.name}</h3> */}
      {/* <p className={styles.heroTitle}>{data.title}</p> */}
      <p
        style={{
          marginBlockStart: 0,
        }}
      >
        {data.shortBio.shortBio}
      </p>
      {/* <Img
        className={styles.heroImage}
        alt={data.name}
        fluid={{
          src:
            'https://webattach.mail.yandex.net/message_part_real/IMG_6971.jpg?exif_rotate=y&no_disposition=y&name=IMG_6971.jpg&sid=YWVzX3NpZDp7ImFlc0tleUlkIjoiMTc4IiwiaG1hY0tleUlkIjoiMTc4IiwiaXZCYXNlNjQiOiJBMVFUUmtKVElibVJ0bkk0dVBDakx3PT0iLCJzaWRCYXNlNjQiOiJkVkNvQUZwZUlJeXErZzJZNDRHTWhwclhkYnFmdDFwYjdpekE1S01BeDQwSWdDMmx6c1MxS1ZucFZYQUF4RVh0c0xMZ0xMUzg1dkZTQVZXYnFjalhXakxmNGxDK3NMdGpTTHpOWlhDdGE3YmQ1WWxidzBLZkhweXhnd1JKN0JXSSIsImhtYWNCYXNlNjQiOiIwK0F6ckRPeFRlN0FoRldxSm5NTWxnbkY1YW9IMExmTHMwVURMQ2wrbnZRPSJ9',
        }}
      /> */}
    </div>
  </div>
)
